<template>
  <div class="grid grid-cols-2 gap-3">
    <div
      class="hboxr relative py-5"
      style="max-width: 459px; min-height: 232px"
      v-for="s in 2"
      :key="s"
    >
      <table class="srtable">
        <tbody>
          <tr>
            <td class="text-sm font-semibold srtpad">
              <div class="h-4 w-24 bg-hline rounded-lg"></div>
            </td>
            <td class="text-sm font-medium srtpad">
              <div class="h-4 w-32 bg-hline rounded-lg"></div>
            </td>
          </tr>
          <tr>
            <td class="text-sm font-semibold srtpad">
              <div class="h-4 w-24 bg-hline rounded-lg"></div>
            </td>
            <td class="text-sm font-medium srtpad">
              <div class="h-4 w-32 bg-hline rounded-lg"></div>
            </td>
          </tr>
          <tr>
            <td class="text-sm font-semibold srtpad">
              <div class="h-4 w-24 bg-hline rounded-lg"></div>
            </td>
            <td class="text-sm font-medium srtpad">
              <div class="h-4 w-64 bg-hline rounded-lg"></div>
              <div class="h-4 w-38 mt-1 bg-hline rounded-lg"></div>
              <div class="h-4 w-48 mt-1 bg-hline rounded-lg"></div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="absolute -right-3 bg-hline top-3.5 bottom-4"
        style="width: 21px; border-radius: 4px"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'skeleton-report'
}
</script>
