<template>
  <div class="sm:col-span-3 animate-pulse">
    <div class="flex">
      <div class="flex-1 font-bold text-2xl mt-2.5">
        <div class="h-8 w-38 bg-hline rounded-lg"></div>
      </div>
      <div class="flex justify-end space-x-3">
        <div class="h-8 w-14 bg-hline rounded-lg"></div>
        <div class="h-8 w-14 bg-hline rounded-lg"></div>
      </div>
    </div>

    <div class="hboxr relative mt-2 px-4 pb-4">
      <div class="overflow-x-auto mt-5">
        <table class="table w-full">
          <thead>
            <tr>
              <th></th>
              <th class="capitalize font-medium text-sm">
                <div class="h-4 w-28 bg-hline rounded-lg"></div>
              </th>
              <th class="capitalize font-medium text-sm">
                <div class="h-4 w-28 bg-hline rounded-lg"></div>
              </th>
              <th class="capitalize font-medium text-sm">
                <div class="h-4 w-28 bg-hline rounded-lg"></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="at in 3" :key="at" class="cursor-pointer">
              <td class="td-left relative pt-4.1" style="text-align: center">
                <div class="h-6 w-6 bg-hline rounded-lg"></div>
                <div
                  class="absolute right-1 top-3"
                  style="
                    height: 36px;
                    border-left: 2px solid #e7e7e7;
                    border-radius: 28px;
                    width: 0;
                  "
                ></div>
              </td>
              <td><div class="h-4 w-28 bg-hline rounded-lg"></div></td>
              <td><div class="h-4 w-28 bg-hline rounded-lg"></div></td>

              <td>
                <div class="h-4 w-28 bg-hline rounded-lg"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'skeleton-attach'
}
</script>
