<template>
  <div class="sm:col-span-2 animate-pulse">
    <div class="hbox relative" style="height: 521px">
      <div class="h-12 relative pt-3.5 z-20" style="border-bottom: 1px solid #e8e4e4">
        <div class="flex justify-center">
          <div class="h-5 w-28 bg-hline rounded-lg"></div>
        </div>
      </div>

      <div class="grid gap-3 chats px-3 py-3" v-for="idx in 3" :key="idx" :id="'c' + idx">
        <div
          :class="isOdd(idx) ? 'justify-self-start' : 'justify-self-end '"
          class="h-6 w-36 bg-hline rounded-lg"
        ></div>
      </div>

      <div class="absolute bottom-0 w-full h-16" style="border-top: 1px solid #e8e4e4">
        <div class="flex space-x-3 pt-4 px-3.1">
          <div class="h-8 w-full bg-hline rounded-full"></div>
          <div class="h-8 w-9 bg-hline rounded-full"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'skeleton-chat',
  methods: {
    isOdd(num) {
      return num % 2
    }
  }
}
</script>
