<template>
  <div class="sm:col-span-3 animate-pulse">
    <div class="flex">
      <div class="flex-1 font-bold text-2xl mt-2.5">
        <div class="h-8 w-38 bg-hline rounded-lg"></div>
      </div>
    </div>

    <div class="hboxr relative mt-3 px-4 pb-4">
      <div class="overflow-x-auto mt-5">
        <table class="table w-full">
          <thead>
            <tr>
              <th><div class="h-4 w-28 bg-hline rounded-lg"></div></th>
              <th><div class="h-4 w-28 bg-hline rounded-lg"></div></th>
              <th><div class="h-4 w-28 bg-hline rounded-lg"></div></th>
              <th>
                <div class="h-4 w-28 bg-hline rounded-lg"></div>
              </th>
              <th>
                <div class="h-4 w-28 bg-hline rounded-lg"></div>
              </th>
              <th>
                <div class="h-4 w-28 bg-hline rounded-lg"></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="at in 8" :key="at" class="cursor-pointer">
              <td>
                <div class="h-4 w-28 bg-hline rounded-lg"></div>
              </td>
              <td><div class="h-4 w-28 bg-hline rounded-lg"></div></td>
              <td><div class="h-4 w-28 bg-hline rounded-lg"></div></td>
              <td><div class="h-4 w-28 bg-hline rounded-lg"></div></td>
              <td><div class="h-4 w-28 bg-hline rounded-lg"></div></td>

              <td>
                <div class="h-4 w-28 bg-hline rounded-lg"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'skeleton-sparepart'
}
</script>
