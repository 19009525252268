<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          style="max-width: 200px"
          @click="$router.go(-1)"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">Detail Ticket</span>
        </div>
      </div>

      <div class="z-10">
        <div class="hcard pb-10">
          <div class="relative z-10 animate-pulse" v-if="loading">
            <div class="h-5 w-36 bg-hline rounded-lg absolute right-8 top-3.5"></div>
          </div>
          <div class="relative z-10" v-else>
            <button
              class="btn btn-ghost btn-sm font-semibold text-xs capitalize text-black absolute right-8 top-3.5"
              v-if="ticket.status == 'done' || ticket.status == 'closed'"
              :disabled="loadInvoice"
              :class="loadInvoice == true ? 'loading' : ''"
              @click="downloadInvoice"
            >
              <SvgDownload class="h-3 w-3" style="margin: auto 8px auto 0" v-if="!loadInvoice" />
              <span>Download Report</span>
            </button>
          </div>

          <!--  -->
          <div class="grid sm:grid-cols-5 gap-2 py-3.5 pl-8 pr-7 animate-pulse" v-if="loading">
            <div class="sm:col-span-3">
              <div class="text-2xl text-hblue3 font-bold">
                <div class="h-9 max-w-xs bg-hline rounded-lg"></div>
              </div>
              <div class="text-hwarning2 text-sm leading-none mt-1">
                <div class="h-3 w-28 bg-hline rounded-lg"></div>
              </div>
            </div>
            <div class="sm:col-span-2 relative">
              <div class="font-semibold text-black absolute bottom-0 left-0">
                <div class="h-5 w-32 bg-hline rounded-lg"></div>
              </div>
            </div>
          </div>
          <div class="grid sm:grid-cols-5 gap-2 py-3.5 pl-8 pr-7" v-else>
            <div class="sm:col-span-3">
              <div class="text-2xl text-hblue3 font-bold">
                {{ ticket.ticketType ? ticket.ticketType.data.alias : '-' }}
              </div>
              <!-- <p class="text-hwarning2 text-sm leading-none">
                {{ ticket.ticketType ? ticket.ticketType.data.name : '-' }}
              </p> -->
            </div>
            <div class="sm:col-span-2 relative hidden sm:block">
              <div class="font-semibold text-black absolute bottom-0 left-0">Ticket Progress</div>
            </div>
          </div>
          <!--  -->

          <!--  -->
          <skeleton-detail v-if="loading" />
          <div class="grid sm:grid-cols-5 gap-2" v-else>
            <div class="col-span-3">
              <div class="flex relative mr-7">
                <div
                  class="w-full h-10.1 bg-hwarning"
                  style="max-width: 328px; border-radius: 0 5px 5px 0"
                ></div>
                <div class="w-full h-10.1 bg-hblue absolute left-0" style="max-width: 317px">
                  <p class="text-2xl font-bold sm:ml-8 mt-1 text-white">
                    {{ ticket.number }}
                  </p>
                </div>
                <div
                  class="h-10.1 text-center pt-2 px-3.5 text-white font-bold -ml-2 sm:ml-2 z-10"
                  :class="{
                    'bg-statusnew text-statustextnew': ticket.status == 'new',
                    'bg-statusconfirmed text-statustextconfirmed': ticket.status == 'confirmed',
                    'bg-statusstarted text-statustextstarted': ticket.status == 'started',
                    'bg-statusrestarted text-statustextrestarted': ticket.status == 'restarted',
                    'bg-statusonhold text-statustextonhold': ticket.status == 'held',
                    'bg-statusdone text-statustextdone': ticket.status == 'done',
                    'bg-statusclosed text-statustextclosed': ticket.status == 'closed'
                  }"
                  style="max-width: 136px; border-radius: 5px"
                >
                  <span class="capitalize">
                    {{ ticket.status }}
                  </span>
                </div>
              </div>

              <div class="grid sm:grid-cols-2 gap-x-3 gap-y-2 sm:gap-y-4 mt-7 ml-8">
                <div class="grid gap-y-4">
                  <div>
                    <div class="flex">
                      <IconDate class="w-3.5 h-3.5" style="margin: auto 8px auto 0" />
                      <span class="text-sm text-hgray5 font-medium">Created at</span>
                    </div>
                    <div class="font-semibold mt-0.5" v-if="ticket.created_at">
                      {{ ticket.created_at.date | moment('dddd, D/MM/YYYY h:mm a') }}
                    </div>
                  </div>

                  <div v-if="ticket.division">
                    <div class="flex">
                      <IconDivision class="w-3.5 h-3.5" style="margin: auto 8px auto 0" />
                      <span class="text-sm text-hgray5 font-medium">Division</span>
                    </div>
                    <div class="font-semibold mt-0.5">
                      {{ ticket.division ? ticket.division.data.name : '-' }}
                    </div>
                  </div>

                  <div v-if="ticket.department">
                    <div class="flex">
                      <IconDivision class="w-3.5 h-3.5" style="margin: auto 8px auto 0" />
                      <span class="text-sm text-hgray5 font-medium">Department</span>
                    </div>
                    <div class="font-semibold mt-0.5">
                      {{ ticket.department ? ticket.department.data.name : '-' }}
                    </div>
                  </div>

                  <div>
                    <div class="flex">
                      <IconPic class="w-3.5 h-3.5" style="margin: auto 8px auto 0" />
                      <span class="text-sm text-hgray5 font-medium">PIC</span>
                    </div>
                    <div class="font-semibold mt-0.5">
                      {{ ticket.assigneeName ? ticket.assigneeName : '-' }}
                    </div>
                  </div>

                  <div v-if="ticket.ticketType && tt.includes(ticket.ticketType.data.id)">
                    <div class="flex">
                      <IconInstrument class="w-3.5 h-3.5" style="margin: auto 8px auto 0" />
                      <span class="text-sm text-hgray5 font-medium">Instrument</span>
                    </div>
                    <div class="font-semibold mt-0.5">
                      {{
                        ticket.instrument.data
                          ? `${ticket.instrument.data.type} - ${ticket.instrument.data.serial_number}`
                          : '-'
                      }}
                    </div>
                  </div>
                </div>

                <div class="grid gap-y-4 auto-rows-min">
                  <div v-if="ticket.request">
                    <div class="flex">
                      <IconAction class="w-3.5 h-3.5" style="margin: auto 8px auto 0" />
                      <span class="text-sm text-hgray5 font-medium">Request Type</span>
                    </div>
                    <div class="font-semibold mt-0.5">
                      {{ ticket.request }}
                    </div>
                  </div>
                  <div v-if="ticket.staff_name">
                    <div class="flex">
                      <IconHuman class="w-3.5 h-3.5" style="margin: auto 8px auto 0" />
                      <span class="text-sm text-hgray5 font-medium">Customer Name</span>
                    </div>
                    <div class="font-semibold mt-0.5">
                      {{ ticket.staff_name ? ticket.staff_name : '-' }}
                    </div>
                  </div>

                  <div v-if="ticket.staff_phone_number">
                    <div class="flex">
                      <IconPhone class="w-3.5 h-3.5" style="margin: auto 8px auto 0" />
                      <span class="text-sm text-hgray5 font-medium">Phone</span>
                    </div>
                    <div class="font-semibold mt-0.5">
                      {{ ticket.staff_phone_number ? ticket.staff_phone_number : '-' }}
                    </div>
                  </div>

                  <div v-if="ticket.priority">
                    <div class="flex mb-0.5">
                      <IconPriority
                        class="w-3.5 h-3.5 fill-current text-hgray5"
                        style="margin: auto 8px auto 0"
                      />
                      <span class="text-sm text-hgray5 font-medium">Priority</span>
                    </div>
                    <div
                      class="py-0.6 px-2 mt-1 text-center text-white text-xs font-semibold rounded-5px"
                      style="max-width: 109px"
                      :class="{
                        'bg-hlow text-htextlow': ticket.priority.toLowerCase() == 'low',
                        'bg-hmedium text-htextmedium': ticket.priority.toLowerCase() == 'medium',
                        'bg-hhigh text-htexthigh': ticket.priority.toLowerCase() == 'high'
                      }"
                    >
                      <span class="capitalize">
                        {{ ticket.priority }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid gap-3 ml-8 mr-7">
                <div class="mt-5">
                  <div class="flex">
                    <IconNote class="w-3.5 h-3.5" style="margin: auto 8px auto 0" />
                    <span class="text-sm text-hgray5 font-medium">Description</span>
                  </div>
                  <div
                    class="py-2 px-3 bg-white mt-2"
                    style="border: 1px solid #e3e3e3; border-radius: 7px; min-height: 110px"
                  >
                    <span class="text-sm font-medium">
                      {{ ticket.description }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="flex mt-4 ml-8 mr-7">
                <button
                  v-if="ticket.status == 'new'"
                  @click="toAction('cancel')"
                  class="w-full btn btn-outline capitalize"
                  style="border: 0.5px solid #000000; height: 37px; border-radius: 15px"
                >
                  cancel ticket
                </button>
                <button
                  v-if="ticket.status == 'done'"
                  @click="toClose"
                  class="w-full btn btn-outline capitalize"
                  style="border: 0.5px solid #000000; height: 37px; border-radius: 15px"
                >
                  close ticket
                </button>
              </div>
            </div>
            <div class="col-span-2 pr-2 sm:pr-8 mt-5 sm:mt-0 ml-8 sm:ml-0">
              <div class="hbox py-5 px-1">
                <my-scroll :ops="configProgress" ref="stp">
                  <div class="grid sm:grid-cols-12 text-gray-50">
                    <div
                      class="flex md:contents xs:ml-9 sm:ml-0"
                      v-for="(p, idx) in listProgress"
                      :key="idx"
                    >
                      <div class="col-start-2 col-end-4 sm:mr-10 md:mx-auto relative">
                        <div
                          class="h-full w-6 flex items-center justify-center"
                          :class="{
                            'pt-8': idx == 0 && listProgress.length > 1,
                            'pb-14': idx + 1 == listProgress.length,
                            'pt-8 pb-14': idx == 0 && listProgress.length == 1
                          }"
                        >
                          <div
                            class="h-full w-1 pointer-events-none"
                            :class="idx == 0 ? 'bg-hblue1' : 'bg-gray-300'"
                          ></div>
                        </div>
                        <div
                          class="w-6 h-6 absolute top-0 rounded-full shadow text-center"
                          :class="idx == 0 ? 'bg-hblue1 mt-6.1' : 'bg-gray-300 mt-6.1'"
                        ></div>
                      </div>
                      <div class="col-start-4 col-end-12 py-1 px-3 rounded-xl mb-3 mr-auto w-full">
                        <div class="font-medium text-xs mb-1 text-black" v-if="p.created_at">
                          {{ p.created_at.date | moment('DD MMM YYYY HH:mm') }}
                        </div>
                        <div class="flex">
                          <div
                            class="p-1 px-3 font-medium rounded-l-md text-sm"
                            :class="idx == 0 ? 'text-black bg-hwarning' : 'text-white bg-hgray1'"
                          >
                            {{ p.status }}
                          </div>
                          <div
                            class="p-1 px-3 font-medium rounded-r-md text-sm"
                            :class="idx == 0 ? 'text-white bg-hblue' : 'text-white bg-hgray2'"
                          >
                            {{ p.role }}
                          </div>
                        </div>
                        <div class="font-semibold text-xs mt-1 text-black">
                          {{ p.created_by }}
                        </div>
                      </div>
                    </div>
                  </div>
                </my-scroll>
              </div>
            </div>
          </div>
          <!--  -->
        </div>

        <!--  -->

        <div class="grid grid-cols-1 sm:grid-cols-5 gap-5 mt-8">
          <skeleton-chat v-if="loadChat" />
          <div class="sm:col-span-2" v-else>
            <div class="hbox-chat relative" style="height: 521px">
              <div class="h-12 relative pt-3.5 z-20" style="border-bottom: 1px solid #e8e4e4">
                <div class="flex justify-center">
                  <ChatColored style="margin: auto 7px auto 0" />
                  <p class="text-center text-sm font-bold">Chat Room</p>
                </div>
              </div>
              <div class="w-full" v-if="!chats.length">
                <div class="-ml-6">
                  <img :src="NoChat" class="mx-auto mt-24" />
                </div>

                <div class="text-htext font-semibold text-sm text-center">
                  Mari memulai obrolan!
                </div>
              </div>

              <my-scroll :ops="configChat" ref="vtc">
                <div class="slot-load" slot="load-start">
                  <IconSpinner
                    class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 text-center"
                  />
                </div>
                <div
                  class="grid gap-3 chats px-3 py-3"
                  v-for="(c, idx) in chats"
                  :key="idx"
                  :id="'c' + idx"
                >
                  <div
                    :class="
                      c.user && c.user.user_type == 'employee'
                        ? 'justify-self-start'
                        : 'justify-self-end '
                    "
                  >
                    <div
                      class="p-1 px-3.5"
                      :class="
                        c.user && c.user.user_type == 'employee' ? 'box-receiver' : 'box-sender'
                      "
                      style="max-width: 300px"
                    >
                      <div class="text-sm font-bold">
                        {{ c.user ? c.user.name : 'anon' }}
                      </div>
                      <div class="text-sm break-words" v-html="c.comment"></div>
                    </div>
                    <div v-if="c.links && c.links.length">
                      <div
                        v-for="l in c.links"
                        :key="l.id"
                        class="py-1 px-3.5 mt-2"
                        :class="
                          c.user && c.user.user_type == 'employee'
                            ? 'box-receiver-link'
                            : 'box-sender-link'
                        "
                        :style="l.type == 'youtube' ? 'width: 300px' : 'max-width: 300px'"
                      >
                        <div :class="l.type == 'youtube' ? 'my-1 aspect-w-16 aspect-h-9' : ''">
                          <iframe
                            v-if="l.type == 'youtube'"
                            :src="l.source"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>

                          <a
                            :href="l.source"
                            target="_blank"
                            v-else
                            class="text-hblue hover:text-hblue6 text-sm"
                          >
                            {{ l.title }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex text-10px mt-1 text-hgray7"
                      :class="
                        c.user && c.user.user_type == 'employee' ? 'text-right' : 'justify-end'
                      "
                      v-if="c.created_at"
                    >
                      {{ c.created_at.date | moment('from', 'now') }}
                    </div>
                  </div>
                </div>
              </my-scroll>

              <div class="absolute bottom-0 w-full h-16" style="border-top: 1px solid #e8e4e4">
                <div class="flex space-x-3 pt-2.1 pr-3.1">
                  <SvgWriter class="absolute bottom-4.2 left-5" />
                  <input
                    type="text"
                    placeholder="Type your message here....."
                    class="h-11 input input-bordered w-full pl-11.5"
                    style="border-radius: 56px"
                    v-model="comment"
                    v-on:keyup.enter="sendChat"
                  />
                  <button
                    :disabled="loadSend"
                    class="btn btn-circle h-11 btn-secondary hover:bg-htealdark pl-0.8 border-0"
                    style="width: 2.75rem"
                    @click="sendChat"
                  >
                    <SvgSend />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <skeleton-attachment v-if="loadAttach" />
          <div class="sm:col-span-3" v-else>
            <div class="flex">
              <div class="flex-1 font-bold text-2xl mt-2.5">Attachment</div>
              <div class="flex justify-end">
                <div
                  class="py-1 px-2.5 cursor-pointer rounded hover:bg-gray-200 text-center"
                  style="width: 64.7px"
                  @click="MODAL_UPLOAD_ATTACHMENT(true)"
                >
                  <AddColored class="mx-auto h-5.2 w-5.2" />
                  <span class="text-sm font-medium">add</span>
                </div>

                <div
                  class="py-1 px-2.5 cursor-pointer rounded hover:bg-gray-200 text-center"
                  style="width: 64.7px"
                  @click="toAction('deleteAttachment')"
                >
                  <DeleteColored class="mx-auto h-5.2 w-5.2" />
                  <span class="text-sm font-medium">delete</span>
                </div>
              </div>
            </div>

            <div class="hboxr relative mt-2 px-4 pb-4">
              <div class="attachment overflow-x-auto">
                <table class="table w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th class="capitalize font-medium text-sm">File Name</th>
                      <th class="capitalize font-medium text-sm">Description</th>
                      <th class="capitalize font-medium text-sm">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="at in attachments" :key="at.id" class="cursor-pointer">
                      <td class="td-left relative pt-4.1" style="text-align: center">
                        <p
                          class="absolute left-0 top-0 h-full w-3 bg-hblue"
                          style="border-radius: 9px 0 0 9px"
                        ></p>
                        <label>
                          <input type="checkbox" class="checkbox ml-1" v-model.trim="at.checked" />
                        </label>
                        <div
                          class="absolute right-1 top-3"
                          style="
                            height: 36px;
                            border-left: 2px solid #176fa9;
                            border-radius: 28px;
                            width: 0;
                          "
                        ></div>
                      </td>
                      <td @click="toDetail(at)">{{ at.name }}</td>
                      <td @click="toDetail(at)">{{ at.description }}</td>

                      <td class="td-right" @click="toDetail(at)">
                        <span>
                          {{ at.created_at | moment('ddd, DD-MM-YYYY HH:mm') }}
                        </span>
                      </td>
                    </tr>
                  </tbody>

                  <tr v-if="!attachments.length" style="border: 0; box-shadow: 0">
                    <td colspan="4" style="border: 0">
                      <img :src="NoTicket" class="mx-auto mt-18" />
                      <div class="text-htext font-semibold text-sm text-center">
                        No attachment yet
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <skeleton-sparepart class="mt-12" v-if="loadPart" />
        <div class="grid sm:grid-cols-3 gap-3 mt-12" ref="sparepart" v-else>
          <div class="flex flex-col space-y-3" v-if="picImg">
            <div class="text-2xl font-semibold">Image</div>
            <img :src="`${url + picImg}`" class="rounded-lg" />
          </div>
          <div class="flex flex-col space-y-3" :class="picImg ? 'sm:col-span-2' : ' sm:col-span-3'">
            <div class="text-2xl font-semibold">Spare part</div>
            <div class="hboxr">
              <div class="sparepart overflow-x-auto">
                <table class="table w-full">
                  <thead>
                    <tr>
                      <th
                        class="capitalize font-medium text-sm"
                        style="border-top-left-radius: 9px"
                      >
                        Part Number
                      </th>
                      <th class="capitalize font-medium text-sm">Sparepart Name</th>
                      <th class="capitalize font-medium text-sm">Quantity</th>
                      <th class="capitalize font-medium text-sm">Status</th>
                      <th
                        class="capitalize font-medium text-sm"
                        style="border-top-right-radius: 9px"
                      >
                        Note
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="sp in spareparts" :key="sp.id">
                      <td class="text-sm font-medium">{{ sp.part_number }}</td>
                      <td class="text-sm font-medium">{{ sp.description }}</td>
                      <td class="text-sm font-medium">{{ sp.quantity }}</td>
                      <td class="text-sm font-medium">
                        <div class="py-0.5 text-sm" style="border-radius: 18px">
                          {{ sp.status }}
                        </div>
                      </td>
                      <td class="text-sm font-medium">
                        {{ sp.remarks }}
                      </td>
                    </tr>

                    <tr v-if="!spareparts.length" style="border: 0">
                      <td colspan="5" style="border: 0">
                        <img :src="NoSparepart" class="mx-auto mt-28" />
                        <div class="text-htext font-semibold text-sm text-center">
                          There is no spare part
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!--  -->

        <!--  -->
        <div class="grid grid-cols-1 gap-3 mt-12">
          <div class="text-2xl font-semibold" v-if="service_reports.length > 0">Service Report</div>
          <div class="h-8 w-38 bg-hline rounded-lg" v-if="loadReport"></div>

          <skeleton-report v-if="loadReport" />
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-3" v-else>
            <div
              class="hboxr relative py-5 px-5 cursor-pointer"
              style="max-width: 459px; min-height: 232px"
              v-for="s in service_reports"
              :key="s.id"
              @click="toSparePart(s)"
            >
              <table class="srtable">
                <tbody>
                  <tr>
                    <td class="text-sm font-semibold srtpad">Problem</td>
                    <td class="text-sm font-medium srtpad">:</td>
                    <td class="text-sm font-medium srtpad">{{ s.problem }}</td>
                  </tr>
                  <tr>
                    <td class="text-sm font-semibold srtpad">Fault Description</td>
                    <td class="text-sm font-medium srtpad">:</td>
                    <td class="text-sm font-medium srtpad">{{ s.fault_description }}</td>
                  </tr>
                  <tr>
                    <td class="text-sm font-semibold srtpad">Solution</td>
                    <td class="text-sm font-medium srtpad">:</td>
                    <td class="text-sm font-medium srtpad">
                      {{ s.solution }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                class="absolute -right-3 bg-hwarning top-3.5 bottom-4 hidden sm:block"
                style="width: 21px; border-radius: 4px"
              ></div>
            </div>
          </div>
        </div>

        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import SvgDownload from '@/assets/icons/icon-download.svg?inline'

import IconDate from '@/assets/icons/icon-date.svg?inline'
import IconDivision from '@/assets/icons/icon-division.svg?inline'
import IconHuman from '@/assets/icons/icon-profile.svg?inline'
import IconPhone from '@/assets/icons/icon-phone.svg?inline'
import IconPic from '@/assets/icons/icon-picTicket.svg?inline'
import IconPriority from '@/assets/icons/icon-Priority.svg?inline'
import IconNote from '@/assets/icons/icon-note.svg?inline'
import IconInstrument from '@/assets/icons/icon-instrument.svg?inline'
import IconAction from '@/assets/icons/icon-action.svg?inline'

import ChatColored from '@/assets/icons/icon-chat.svg?inline'
import SvgSend from '@/assets/icons/icon-send.svg?inline'
import SvgWriter from '@/assets/icons/icon-writer.svg?inline'
import AddColored from '@/assets/icons/icon-add.svg?inline'
import DeleteColored from '@/assets/icons/icon-delete.svg?inline'
import IconSpinner from '@/assets/icons/spinner.svg?inline'
// import CheckColored from '@/assets/icons/check-colored.svg?inline'

import NoSparepart from '@/assets/images/no-sparepart.png'
import NoService from '@/assets/images/icon-noService.png'
import NoChat from '@/assets/images/icon-noChat.png'
import NoTicket from '@/assets/images/icon-noTicket.png'

import { mapActions, mapState, mapGetters } from 'vuex'

import SkeletonDetail from '@/components/skeleton/detail'
import SkeletonChat from '@/components/skeleton/chat'
import SkeletonAttachment from '@/components/skeleton/attachment'
import SkeletonSparepart from '@/components/skeleton/sparepart'
import SkeletonReport from '@/components/skeleton/report'

export default {
  name: 'ticket-detail',
  components: {
    IconArrow,
    SvgDownload,

    IconSpinner,
    IconDate,
    IconDivision,
    IconHuman,
    IconPhone,
    IconPic,
    IconPriority,
    IconNote,
    IconInstrument,
    IconAction,

    ChatColored,
    SvgSend,
    SvgWriter,
    AddColored,
    DeleteColored,

    SkeletonDetail,
    SkeletonChat,
    SkeletonAttachment,
    SkeletonSparepart,
    SkeletonReport
    // CheckColored
  },
  data() {
    return {
      NoSparepart,
      NoService,
      NoChat,
      NoTicket,

      loading: false,
      loadStatus: false,
      loadReport: false,
      loadPart: false,
      loadChat: false,
      loadSend: false,
      loadAttach: false,
      loadInvoice: false,

      comment: '',
      spareparts: [],
      service_reports: [],
      tt: [1, 2],
      picImg: null,
      url: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    ...mapState({
      ticket: state => state.ticket.ticket,
      chats: state => state.ticket.chats,
      configChat: state => state.ticket.configChat,
      configProgress: state => state.ticket.configProgress,
      attachment_types: state => state.ticket.attachment_types
    }),
    ...mapGetters('ticket', ['listProgress', 'attachments'])
  },
  watch: {
    chats: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.scrollChat()
          }, 200)
        }
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions('modal', [
      'MODAL_CONFIRM',
      'MODAL_UPLOAD_ATTACHMENT',
      'MODAL_DETAIL_ATTACHMENT',
      'MODAL_REVIEW_TICKET'
    ]),
    ...mapActions('ticket', [
      'GET_TICKET_DETAIL',
      'TICKET_ACTION',
      'TICKET_STATUSES',
      'TICKET_SERVICE_REPORT',
      'TICKET_SPARE_PART',
      'TICKET_DETAIL',
      'GET_TICKET_CHAT',
      'PUSH_CHATS',
      'SEND_TICKET_CHAT',
      'ATTACHMENT_TYPES',
      'GET_TICKET_ATTACHMENTS',
      'DELETE_TICKET_ATTACHMENTS',
      'ATTACHMENT_TYPES',
      'ATTACHMENT_DELETE',
      'DETAIL_ATTACHMENT',
      'INVOICE_TICKET',
      'PIC_IMG'
    ]),
    async getData() {
      this.loading = true
      this.loadStatus = true
      this.loadReport = true
      this.loadChat = true
      this.loadAttach = true
      this.loadPart = true

      let id = this.$route.params.id
      await this.GET_TICKET_DETAIL(id)

      setTimeout(() => (this.loading = false), 500)

      await this.TICKET_STATUSES(id)
      this.loadStatus = false

      await this.GET_TICKET_CHAT(id)

      this.loadChat = false

      await this.GET_TICKET_ATTACHMENTS(id)
      this.loadAttach = false

      let resReport = await this.TICKET_SERVICE_REPORT(id)

      this.loadReport = false

      if (resReport) {
        this.service_reports = resReport
        if (Array.isArray(resReport) && resReport.length) {
          let params = {
            ticket_id: id,
            service_report_id: resReport[0].id
          }
          this.getSparePart(params)
        }
      }

      this.loadPart = false
      this.ATTACHMENT_TYPES()

      let picImg = await this.PIC_IMG(id)

      if (picImg !== false) this.picImg = picImg.image
    },
    async getSparePart(val) {
      this.loadPart = true
      let res = await this.TICKET_SPARE_PART(val)

      if (res) {
        this.spareparts = res
      }

      this.loadPart = false
    },
    async toAction(val) {
      if (val == 'deleteAttachment') await this.ATTACHMENT_DELETE(this.attachments)

      await this.TICKET_ACTION(val)

      this.MODAL_CONFIRM(true)
    },
    toSparePart(s) {
      this.$refs.sparepart.scrollIntoView({ behavior: 'smooth', block: 'center' })

      this.getSparePart({
        ticket_id: this.$route.params.id,
        service_report_id: s.id
      })
    },
    async sendChat() {
      this.loadSend = true
      if (this.comment.length > 1) {
        let res = await this.SEND_TICKET_CHAT({
          ticket_id: this.$route.params.id,
          comment: this.comment
        })

        if (res) {
          this.PUSH_CHATS(res)
          this.comment = ''
        }
      }
      this.loadSend = false
    },
    scrollChat() {
      if (this.chats.length) {
        this.$refs['vtc'].scrollTo(
          {
            y: '150%'
          },
          500
        )
      }
    },
    async toDetail(val) {
      await this.DETAIL_ATTACHMENT(val)
      this.MODAL_DETAIL_ATTACHMENT(true)
    },
    async downloadInvoice() {
      this.loadInvoice = true
      await this.INVOICE_TICKET(this.ticket)
      this.loadInvoice = false
    },
    async toClose() {
      let val = this.ticket
      val.act = 'myticket'
      await this.TICKET_DETAIL(val)
      this.MODAL_REVIEW_TICKET(true)
    }
  }
}
</script>
