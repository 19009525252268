<template>
  <div class="grid grid-cols-5 gap-2 animate-pulse">
    <div class="col-span-3">
      <div class="flex relative mr-7">
        <div class="h-9 w-64 bg-hline rounded-r-lg"></div>
        <div class="h-9 w-22 bg-hline rounded-lg ml-1"></div>
      </div>

      <div class="grid sm:grid-cols-2 gap-x-3 gap-y-2 sm:gap-y-6 mt-7 sm:ml-8">
        <div>
          <div class="flex">
            <div class="text-sm text-hgray5 font-medium">
              <div class="h-4 w-32 bg-hline rounded-lg"></div>
            </div>
          </div>
          <div class="font-semibold mt-0.5">
            <div class="h-5 w-48 bg-hline rounded-lg"></div>
          </div>
        </div>
        <div>
          <div class="flex">
            <div class="text-sm text-hgray5 font-medium">
              <div class="h-4 w-32 bg-hline rounded-lg"></div>
            </div>
          </div>
          <div class="font-semibold mt-0.5">
            <div class="h-5 w-48 bg-hline rounded-lg"></div>
          </div>
        </div>
        <div>
          <div class="flex">
            <div class="text-sm text-hgray5 font-medium">
              <div class="h-4 w-33 bg-hline rounded-lg"></div>
            </div>
          </div>
          <div class="font-semibold mt-0.5">
            <div class="h-5 w-48 bg-hline rounded-lg"></div>
          </div>
        </div>
        <div>
          <div class="flex">
            <div class="text-sm text-hgray5 font-medium">
              <div class="h-4 w-33 bg-hline rounded-lg"></div>
            </div>
          </div>
          <div class="font-semibold mt-0.5">
            <div class="h-5 w-48 bg-hline rounded-lg"></div>
          </div>
        </div>
        <div>
          <div class="flex">
            <div class="text-sm text-hgray5 font-medium">
              <div class="h-4 w-32 bg-hline rounded-lg"></div>
            </div>
          </div>
          <div class="font-semibold mt-0.5">
            <div class="h-5 w-48 bg-hline rounded-lg"></div>
          </div>
        </div>
        <div>
          <div class="flex">
            <div class="text-sm text-hgray5 font-medium">
              <div class="h-4 w-32 bg-hline rounded-lg"></div>
            </div>
          </div>
          <div class="font-semibold mt-0.5">
            <div class="h-7 w-32 bg-hline rounded-lg"></div>
          </div>
        </div>
      </div>
      <div class="grid gap-3 mt-5 sm:ml-8 mr-7">
        <div>
          <div class="flex">
            <div class="text-sm text-hgray5 font-medium">
              <div class="h-4 w-38 bg-hline rounded-lg"></div>
            </div>
          </div>
          <div class="font-semibold mt-0.5">
            <div class="h-18 w-full bg-hline rounded-lg"></div>
          </div>
        </div>
      </div>

      <div class="mt-5 sm:ml-8 sm:mr-7">
        <div class="h-8 w-full bg-hline rounded-lg"></div>
      </div>
    </div>
    <div class="col-span-2 pr-8">
      <div class="hbox py-5 px-1">
        <div class="flex flex-col md:grid grid-cols-12 text-gray-50">
          <div class="flex md:contents" v-for="n in 3" :key="n">
            <div class="col-start-2 col-end-4 mr-10 md:mx-auto relative">
              <div
                class="h-full w-6 flex items-center justify-center"
                :class="{
                  'pt-8': n == 1,
                  'pb-14': n == 3
                }"
              >
                <div class="h-full w-1 pointer-events-none bg-gray-300 mt-7"></div>
              </div>
              <div
                class="w-6 h-6 absolute top-0 rounded-full shadow text-center bg-gray-300 mt-7"
              ></div>
            </div>
            <div class="col-start-4 col-end-12 py-1 px-3 rounded-xl mb-3 mr-auto w-full">
              <div class="font-medium text-xs mb-1 text-black">
                <div class="h-4 w-38 bg-hline rounded-lg"></div>
              </div>
              <div class="flex">
                <div class="h-8 w-48 bg-hline rounded-lg"></div>
              </div>
              <div class="font-semibold text-xs mt-1 text-black">
                <div class="h-4 w-38 bg-hline rounded-lg"></div>
              </div>
            </div>
          </div>
          =
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'skeleton-detail'
}
</script>
